import VueRouter from 'vue-router'
import IndexPage from "../pages/IndexPage"
import DocumentPage from "../pages/DocumentPage"
import UploadDocumentPage from "../pages/UploadDocumentPage"
import QueryDocumentRecordsPage from "../pages/QueryDocumentRecordsPage"
import DocumentRecordDetailPage from "../pages/DocumentRecordDetailPage"
import ConvertPage from "../pages/ConvertPage"

import store from '../store'

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: '/index',
    }
    ,
    {
      name: 'Index',
      path: '/index',
      component: IndexPage
    },
    {
      name: 'Upload',
      path: '/upload',
      component: UploadDocumentPage
    },
    {
      name: "Document",
      path: "/document",
      component: DocumentPage,
      children: [
        {
          name: "QueryDocumentRecordsPage",
          path: "query",
          component: QueryDocumentRecordsPage
        },
        {
          name: "DocumentRecordDetailPage",
          path: "detail",
          component: DocumentRecordDetailPage
        },
      ]
    },
    {
      name: 'Convert',
      path: '/convert',
      component: ConvertPage
    },
  ]
});

router.beforeEach((to, from, next) => {
  store.commit("setRouterInfo", to.name);
  next();
});

export default router;