import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueWordcloud from 'vue-b2wordcloud'
import App from './App.vue'

//引入VueRouter
import VueRouter from 'vue-router'
//引入路由器
import router from './router'

import store from './store'

import 'bootstrap'


Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueWordcloud);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
