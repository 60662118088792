<template>
  <div class="DocumentRecordDetailPage-Container">
    <el-result
      icon="info"
      title="提示"
      subTitle="基于安全性考量，查询报告页面不可刷新，请前往查询页面重新获取报告"
      v-if="$store.state.recordDetail === null"
    >
      <template slot="extra">
        <el-button type="primary" size="medium" v-on:click="routeToQuery"
          >返回</el-button
        >
      </template>
    </el-result>
    <div class="DetailData-Showcase" v-else>
      <h1>{{ detailData.id }} - {{ detailData.fileName }} 的文档分析报告</h1>
      <el-divider content-position="left"
        ><i class="el-icon-tickets"></i
      ></el-divider>
      <div style="display: flex">
        <div class="AnalyzedInfo-Showcase">
          <el-collapse v-model="activeAllNames">
            <el-collapse-item name="1">
              <template slot="title">
                <el-tag effect="plain"> 关键信息 KeyInformation </el-tag>
              </template>
              <p>{{ detailData.keyInformation }}</p>
            </el-collapse-item>
            <el-collapse-item name="2">
              <template slot="title">
                <el-tag effect="plain"> 主体 MainBody </el-tag>
              </template>
              <p>{{ detailData.mainBody }}</p>
            </el-collapse-item>
            <el-collapse-item name="3">
              <template slot="title">
                <el-tag effect="plain"> 归纳总结 Summary </el-tag>
              </template>
              <p>{{ detailData.summary }}</p>
            </el-collapse-item>
            <el-collapse-item name="4">
              <template slot="title">
                <el-tag effect="plain"> 重要段落 Important Paragraphs </el-tag>
              </template>
              <div style="padding-right: 10px">
                <ul class="ImportantParagraphs-List">
                  <li
                    v-for="(paragraph, index) in detailData.importantParagraphs"
                    :key="index"
                    style="margin-bottom: 10px"
                  >
                    <el-link>{{ paragraph }}</el-link>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
            <el-collapse-item name="5">
              <template slot="title">
                <el-tag effect="plain"> 重要句子 Important Sentences </el-tag>
              </template>
              <div style="padding-right: 10px">
                <ul class="ImportantSentences-List">
                  <li
                    v-for="(sentence, index) in detailData.importantSentences"
                    :key="index"
                    style="margin-bottom: 10px"
                  >
                    <el-link>{{ sentence }}</el-link>
                  </li>
                </ul>
              </div>
            </el-collapse-item>
            <el-collapse-item name="6">
              <template slot="title">
                <el-tag effect="plain"> 术语 Terminologies </el-tag>
              </template>
              <div style="width: 100%; height: 100%; background-color: black">
                <vue-wordcloud
                  style="height: 500px"
                  :options="wordCloudOptions"
                  :loading="false"
                />
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div
          class="QAs-Container"
          v-loading="qaContainerLoading"
          element-loading-text="正在解析并获取答案中"
        >
          <el-alert
            title="开始你的第一次提问吧"
            type="success"
            :closable="false"
            center
            v-if="
              $store.state.recordDetail.qAs === null ||
              $store.state.recordDetail.qAs.length <= 0
            "
          >
          </el-alert>
          <div class="QAItems-Showcase">
            <QAItem
              v-for="qaData in detailData.qAs"
              :key="qaData.id"
              :QAData="qaData"
            ></QAItem>
          </div>
          <div class="QustionInputAndSenderButton">
            <el-input
              placeholder="请输入你对文档的任何问题"
              v-model="questionInput"
              clearable
              maxlength="50"
              show-word-limit
            >
            </el-input>
            <el-button
              type="primary"
              icon="el-icon-s-promotion

"
              style="margin-left: 10px"
              v-on:click="sendNewQuestion"
              >发送</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QAItem from "../components/QAItem.vue";
import pubsub from "pubsub-js";
export default {
  name: "DocumentRecordDetailPage",
  data() {
    return {
      activeAllNames: ["1", "2", "3", "4", "5", "6"],
      detailData: null,
      wordCloudOptions: null,
      questionInput: null,
      receiveAskNewQuestionSuccessEventPID: null,
      receiveAskNewQuestionFailedEventPID: null,
      qaContainerLoading: false,
    };
  },
  methods: {
    async sendNewQuestion() {
      const password = sessionStorage.getItem("password");
      const senderCredential = sessionStorage.getItem("senderCredential");
      const documentId = sessionStorage.getItem("documentId");

      if (!password) {
        this.$notify.error({
          title: "提问失败",
          message: "密钥缺失，请重新查询该文档记录",
        });
        return;
      }

      if (!senderCredential) {
        this.$notify.error({
          title: "提问失败",
          message: "查询凭证缺失，请重新查询该文档记录",
        });
        return;
      }

      if (!documentId) {
        this.$notify.error({
          title: "提问失败",
          message: "文档ID缺失，请重新查询该文档记录",
        });
        return;
      }

      if (this.isBlank(this.questionInput) === true) {
        this.$notify.error({
          title: "提问失败",
          message: "请至少输入一点内容再提问吧",
        });
        return;
      }

      this.qaContainerLoading = true;
      this.$store.state.signalRConnection.invoke(
        "AskNewQuestion",
        senderCredential,
        parseInt(documentId),
        password,
        this.questionInput
      );
    },
    addNewQA(_, qaObj) {
      this.qaContainerLoading = false;
      this.questionInput = null;
      this.detailData.qAs.push(qaObj);
    },
    notifySendNewQuestionFailed(_, failReason) {
      this.$notify.error({
        title: "提问失败",
        message: failReason,
      });
    },
    isBlank(str) {
      return !str || /^\s*$/.test(str);
    },
    routeToQuery() {
      this.$router.replace({
        path: "/document/query",
      });
    },
  },
  created() {
    this.detailData = this.$store.state.recordDetail;
    // 词云配置
    this.wordCloudOptions = {
      list: this.$store.state.recordDetail.terminologies.map((item) => {
        return [item, Math.floor(Math.random() * (1000 - 100 + 1)) + 100];
      }),
      tooltip: {
        show: true,
        formatter: function (item) {
          return "<div>" + item[0] + "</div>";
        },
      },
      shadowColor: "rgba(0,0,0,0.9)",
      shadowOffsetX: 2,
      shadowOffsetY: 2,
      shadowBlur: 5,
      minFontSize: 20,
      maxFontSize: 40,
      color: [
        ["#f00", "#ff0"],
        "#f00",
        "green",
        "#333",
        "blue",
        ["blue", "white"],
      ],
    };
    this.receiveAskNewQuestionSuccessEventPID = pubsub.subscribe(
      "ReceiveAskNewQuestionSuccessEvent",
      this.addNewQA
    );
    this.receiveAskNewQuestionFailedEventPID = pubsub.subscribe(
      "ReceiveAskNewQuestionFailedEvent",
      this.notifySendNewQuestionFailed
    );
  },
  beforeDestroy() {
    if (this.receiveAskNewQuestionSuccessEventPID) {
      pubsub.unsubscribe(this.receiveAskNewQuestionSuccessEventPID);
    }
  },
  components: {
    QAItem,
  },
};
</script>

<style scoped>
h1 {
  margin: 0;
  padding: 0;
}
p {
  font-size: 16px;
}
li span {
  font-size: 16px;
}
ul.ImportantParagraphs-List {
  list-style-type: "💡    ";
}
ul.ImportantSentences-List {
  list-style-type: "🚩    ";
}
.DocumentRecordDetailPage-Container {
  padding-left: 5%;
  padding-right: 5%;
  /* height: 100%; */
}
.AnalyzedInfo-Showcase {
  width: 50%;
  border-right: 1px solid #e4e7ed;
}
.QAs-Container {
  width: 50%;
}
.QustionInputAndSenderButton {
  display: flex;
  margin-top: 15px;
}
.QAItems-Showcase {
  overflow: auto;
}
</style>