<template>
  <div
    class="UploadDocument-Container"
    v-loading="isLoading"
    element-loading-text="正在上传和分析中，请不要刷新或切换网页"
  >
    <el-alert
      title="温馨提示"
      type="info"
      description="查询凭据和密码是获取、使用报告的唯一途径，一定要保存。并且保持一定的复杂度以防隐私泄露"
      show-icon
      style="margin-bottom: 20px"
    >
    </el-alert>
    <el-form
      label-position="top"
      label-width="80px"
      :model="uploadDocumentForm"
    >
      <el-form-item label="查询凭据(联系方式)">
        <el-input
          v-model="uploadDocumentForm.credential"
          clearable
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="查询密码">
        <el-input
          v-model="uploadDocumentForm.password"
          clearable
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="文档文件">
        <!-- <el-upload
          class="upload"
          drag
          accept=".doc,.docx,.txt"
          ref="upload"
          action="https://localhost:7049/api/v1/Reader/Documents/Upload"
          :headers="uploadHeader"
          :on-success="uploadSuccess"
          :on-error="uploadFailed"
          :auto-upload="false"
          :limit="1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖拽到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            只允许上传.doc/.docx/.txt文件，尽量不要过大
          </div>
        </el-upload> -->
        <el-upload
          class="upload"
          drag
          accept=".doc,.docx,.txt"
          ref="upload"
          action="https://auth.xn--e-5g8az75bbi3a.com/api/v1/Reader/Documents/Upload"
          :headers="uploadHeader"
          :on-success="uploadSuccess"
          :on-error="uploadFailed"
          :auto-upload="false"
          :limit="1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖拽到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            只允许上传.doc/.docx/.txt文件，尽量不要过大
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button
          style="width: 200px"
          type="success"
          v-on:click="uploadDocument"
          >上传</el-button
        ></el-form-item
      >
    </el-form>
  </div>
</template>

<script>
export default {
  name: "UploadDocumentPage",
  data() {
    return {
      uploadDocumentForm: {
        credential: "",
        password: "",
      },
      isLoading: false,
    };
  },
  computed: {
    uploadHeader() {
      return {
        credential: this.uploadDocumentForm.credential,
        password: this.uploadDocumentForm.password,
      };
    },
  },
  methods: {
    uploadDocument() {
      if (
        this.isBlank(this.uploadDocumentForm.credential) === true ||
        this.isBlank(this.uploadDocumentForm.password) === true
      ) {
        this.$message({
          showClose: true,
          message: "请完整填写凭据和(或)密码后再上传",
          type: "error",
        });
        return;
      }
      this.isLoading = true;
      this.$refs.upload.submit();
    },
    uploadFailed(error) {
      // console.log("上传失败", JSON.parse(error.message).message);
      this.isLoading = false;
      this.$message({
        showClose: true,
        message: JSON.parse(error.message).message,
        type: "error",
      });
    },
    uploadSuccess(response) {
      // console.log("上传成功的响应", response);
      this.$store.dispatch("setRecordDeatilData", response.data).then(() => {
        sessionStorage.setItem("password", this.uploadDocumentForm.password);
        sessionStorage.setItem(
          "senderCredential",
          this.uploadDocumentForm.credential
        );
        sessionStorage.setItem("documentId", response.data.id);
        this.$router.push({
          path: "/document/detail",
        });
      });
    },
    isBlank(str) {
      return !str || /^\s*$/.test(str);
    },
  },
};
</script>

<style scoped>
.UploadDocument-Container {
  padding-left: 15%;
  padding-right: 15%;
}
</style>