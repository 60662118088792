<template>
  <div class="QueryDocumentRecordsPage-Container">
    <div class="Serach">
      <el-input
        type="text"
        style="width: 80%; margin-right: 20px"
        prefix-icon="el-icon-search"
        placeholder="请输入查询凭据"
        v-model="serachCredential"
        maxlength="20"
        show-word-limit
      >
      </el-input>
      <el-button
        type="primary"
        icon="el-icon-search"
        style="width: 5%"
        v-on:click="queryDocumentRecords"
      ></el-button>
    </div>
    <div class="QueryResults-Showcase">
      <el-empty
        :image-size="200"
        :description="statusDescription"
        v-if="queryResultsCount <= 0"
      ></el-empty>
      <el-table
        :data="queryResults"
        stripe
        style="width: 100%"
        v-if="queryResultsCount > 0"
      >
        <el-table-column prop="id" label="编号" width="180"> </el-table-column>
        <el-table-column prop="obscuredFileName" label="文件名" width="300">
        </el-table-column>
        <el-table-column prop="uploadTime" label="上传时间" width="235">
          <template slot-scope="scope">
            <span>{{ scope.row.uploadTime | formatISODateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" width="235">
          <template slot-scope="scope">
            <span>{{ scope.row.updateTime | formatISODateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="150">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 'Analyzed'">{{
              scope.row.status | formatRecordStatus
            }}</el-tag>
            <el-tag type="danger" v-else>{{
              scope.row.status | formatRecordStatus
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="查看">
          <template slot-scope="scope">
            <el-button @click="queryRecordDetail(scope.row.id)" type="text"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "QueryDocumentRecordsPage",
  data() {
    return {
      serachCredential: "",
      statusDescription: "还未进行查询",
      currentPageIndex: 1,
      queryResults: [],
      queryResultsCount: -1,
    };
  },
  methods: {
    queryDocumentRecords() {
      if (this.isBlank(this.serachCredential) == true) {
        this.$message({
          showClose: true,
          message: "填点东西再查吧~",
          type: "error",
        });
        return;
      } else {
        axios({
          // url: `https://localhost:7049/api/v1/Reader/Documents/${this.serachCredential}`,
          url: `https://auth.xn--e-5g8az75bbi3a.com/api/v1/Reader/Documents/${this.serachCredential}`,
          method: "get",
          headers: {
            pageIndex: this.currentPageIndex,
            pageSize: 999,
          },
        })
          .then((response) => {
            // console.log("查询成功", response);
            this.queryResults = response.data.data.items;
            this.queryResultsCount = response.data.data.totalCount;
            if (response.data.data.totalCount === 0) {
              this.statusDescription = "查了，但没有东西哦~";
            }
          })
          .catch(() => {
            console.log("查询失败");
          });
      }
    },
    queryRecordDetail(documentId) {
      this.$prompt("请输入上传文档时的查询密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{0,15}$/,
        inputErrorMessage: "密码必须在15位以下",
      })
        .then(({ value }) => {
          axios({
            // url: `https://localhost:7049/api/v1/Reader/Documents/${this.serachCredential}/${documentId}`,
            url: `https://auth.xn--e-5g8az75bbi3a.com/api/v1/Reader/Documents/${this.serachCredential}/${documentId}`,
            method: "get",
            headers: {
              password: value,
            },
          })
            .then((response) => {
              sessionStorage.setItem("password", value);
              sessionStorage.setItem("senderCredential", this.serachCredential);
              sessionStorage.setItem("documentId", documentId);
              this.$store
                .dispatch("setRecordDeatilData", response.data.data)
                .then(() => {
                  this.$router.push({
                    path: "/document/detail",
                  });
                });
            })
            .catch((error) => {
              this.$message({
                showClose: true,
                message: error.response.data.message,
                type: "error",
              });
            });
        })
        .catch(() => {});
    },
    isBlank(str) {
      return !str || /^\s*$/.test(str);
    },
  },
  filters: {
    formatRecordStatus: function (value) {
      if (value === "Analyzed") {
        return "已分析完毕";
      } else if (value === "AnalysisFailed") {
        return "分析出现错误";
      } else {
        return "已被删除";
      }
    },
    formatISODateTime: function formatDateString(dateString) {
      // 解析日期字符串为 Date 对象
      const date = new Date(dateString);

      // 获取年、月、日、时、分
      const year = date.getFullYear();
      // getMonth() 返回的月份从 0 开始，所以需要 +1
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();

      // 格式化字符串
      // 使用模板字符串来格式化日期，确保月、日、时、分小于10时前面添加0
      return `${year}年${month.toString().padStart(2, "0")}月${day
        .toString()
        .padStart(2, "0")}日 ${hours.toString().padStart(2, "0")}时${minutes
        .toString()
        .padStart(2, "0")}分`;
    },
  },
};
</script>

<style scoped>
.QueryDocumentRecordsPage-Container {
  padding-left: 15%;
  padding-right: 10%;
}
.Serach {
  display: flex;
  margin-bottom: 10px;
}
</style>