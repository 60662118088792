<template>
  <div class="Index-Container">
    <!-- <div class="Left" style="width: 50%; padding-left: 20px; padding-top: 50px">
      <h1>基于大模型的⽂档解析分析与提问系统</h1>
      <el-link
        icon="el-icon-s-promotion
"
        style="font-size: 30px"
        v-on:click="toUpload"
        >即刻体验</el-link
      >
    </div>
    <div class="Right" style="width: 50%">
      <el-image
        src="http://img.xn--e-5g8az75bbi3a.com/imgs/2024/03/0e06b59eb13e08f0.png"
      ></el-image>
    </div> -->

    <div class="hero-bg">
      <header class="container-fluid py-3 px-4">
        <svg
          class="bottom-curve"
          viewBox="0 0 834 99"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M242.5 2.48937C135.3 -9.90908 37.6667 26.7931 0 51.0969V99H834V14.4651C519.5 90.5465 449 26.3726 242.5 2.48937Z"
            fill="white"
          />
        </svg>
        <!----------------- hero ---------------->
        <div class="row gy-5">
          <div class="col-lg-6 text-white" id="Title">
            <h1 class="hero-header mt-5">
              基于第三方大模型的,<br />智能文档解析分析,<br />针对文档内容提问系统
            </h1>
            <p class="hero-subheader mt-3">
              Based on a large model, <br />Intelligently analyze and answer
              document content
            </p>

            <div class="hero-cto mt-5">
              <button
                class="btn get-start btn-primary px-4 py-2 rounded-pill"
                style="background-color: #8828ff; border-color: #8828ff"
                v-on:click="toUpload"
              >
                即刻体验
              </button>
              <!-- <button class="btn trial ms-2 border-0 text-white">
                Try free trial
              </button> -->
            </div>
          </div>
          <!-- src="../assets/img/homeImage.svg" -->
          <div class="col-lg-6">
            <div class="hero-image">
              <img
                class="feature"
                src="../assets/img/右边图.png"
                alt="hero feature image"
              />
            </div>
          </div>
        </div>
      </header>
    </div>

    <!-- ---------------------------task------------------------------------ -->
    <section id="About" class="task-section mt-0 mt-md-5 px-4">
      <div
        class="row align-items-lg-center task-content gx-2 gy-5 justify-content-between flex-column-reverse flex-lg-row"
      >
        <div class="col col-lg-6">
          <img
            class="task-feature"
            src="../assets/img/中间左边图.png"
            alt="task picture"
          />
        </div>
        <div class="col col-lg-6">
          <div class="taskSec-content mt-0 mt-md-5">
            <h1 class="section-heading fs-1 mb-3">128K超大型国产大模型</h1>
            <p class="section-subhead">
              理论最高支持128K字符数的文档解析与分析
              <br />12,800 - 满足解析99.99%文档的需求
              <br />百度国内自研ERNIE-Speed引擎解析速度极快
            </p>
            <!-- <button class="btn mt-4 task-section-cto px-4 py-2 rounded-pill">
              Browse integrations
            </button> -->
          </div>
        </div>
      </div>

      <img
        class="round-shape"
        src="../assets/img/round.svg"
        alt="round shape"
      />
    </section>

    <!-- ---------------------------Chat------------------------------------ -->
    <section class="chat-section mt-5 px-4">
      <div
        class="row chat-content align-items-lg-center gx-2 gy-5 justify-content-between flex-lg-row"
      >
        <div class="col-lg-6 col-sm-12">
          <div class="chatSec-content mt-5" id="Left-Content">
            <h1 class="section-heading fs-1 mb-3">文档信息,一览无余</h1>
            <p class="section-subhead">
              智能分析文档的<span>关键信息</span>、<span>主体</span>、<span>归纳总结</span>、<span>重要段落</span>、<span>重要句子</span>和<span>术语</span>,以段落、列表和词云形式展现<br />文档哪里重要，一看就知道
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <img
            class="chat-feature"
            src="../assets/img/下面右边图.png"
            alt="chat picture"
          />
        </div>
      </div>
    </section>

    <!------------ testimonial ------------>
    <section id="testimonial" class="position-relative">
      <div class="testimonial-wrapper px-md-0 m-auto">
        <div class="row w-100 flex-wrap gx-0 gx-md-3">
          <div class="col col-md-5 ms-0 ms-lg-5 ps-4 ps-lg-5">
            <h1 class="fs-1 section-heading testimonial-header text-white">
              简单好用,才是王道
            </h1>
            <p class="section-subhead mt-3 testimonial-Subheader text-white">
              无需注册，开箱即用
              <br />
              便捷转换，一键Word
            </p>
          </div>
          <div class="col col-md-6 testimonial-card-wrapper-col">
            <div class="d-flex px-3 testimonial-card-wrapper">
              <div class="testimonial-card p-4 py-3 py-md-5 me-0 me-md-3">
                <p class="desc">
                  只需填写查询凭证和查询密码，无需注册，上传文件即刻进行分析
                  <br />
                  后台不存储任何文件，30天无访问，文件内容自动过期，保证隐私
                </p>
                <div class="author mt-3 d-flex align-items-center">
                  <!-- <img src="assets/img/Avatar-2.png" alt="user profile image" /> -->
                  <span class="ms-2">无需注册，开箱即用</span>
                </div>
              </div>
              <div class="testimonial-card p-4 py-3 py-md-5">
                <p class="desc">
                  只支持解析Word格式文档，但提供PDF格式一键转换Word
                  <br />
                  支持99.99% PDF格式，以及带密码、PDF/A读取转换
                </p>
                <div class="author mt-3 d-flex align-items-center">
                  <!-- <img src="assets/img/Avatar-1.png" alt="user profile image" /> -->
                  <span class="ms-2">便捷转换，一键Word</span>
                </div>
              </div>
            </div>

            <span
              class="testimonial-next rounded-circle d-flex justify-content-center align-items-center"
              aria-hidden="true"
              ><i class="fa-solid fa-angle-right"></i
            ></span>
            <span
              class="testimonial-prev bg-white rounded-circle d-flex justify-content-center align-items-center"
              aria-hidden="true"
              ><i class="fa-solid fa-angle-left"></i
            ></span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
export default {
  name: "IndexPage",
  methods: {
    toUpload() {
      this.$router.push({
        path: "/upload",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../assets/css/style.css";
.Index-Container {
  height: 100%;
  width: 100%;
}

#Title {
  position: relative;
  right: -21rem;
  top: 4rem;
  width: 38rem;
  z-index: 9999;
}

#Left-Content {
  position: relative;
  top: -4rem;
}
</style>