<template>
  <div id="app">
    <el-container>
      <el-header
        ><div class="header-container">
          <div class="header-container-left">
            <el-avatar
              :size="60"
              src="http://img.xn--e-5g8az75bbi3a.com/imgs/2024/03/e6a91ffeab5a5dba.png"
            ></el-avatar>
            <!-- <h1>大模型⽂档解析分析与提问系统</h1> -->
          </div>
          <el-menu
            :default-active="$store.state.routerNavigatorName"
            class="el-menu"
            mode="horizontal"
            @select="handleSelect"
            style="border-bottom: 0; margin-top: 10px"
          >
            <el-menu-item index="/index">首页</el-menu-item>
            <el-menu-item index="/upload">上传文档</el-menu-item>
            <el-menu-item index="/document/query">查询已上传记录</el-menu-item>
            <el-menu-item index="/convert">格式转换</el-menu-item>
          </el-menu>
        </div>
      </el-header>
      <el-divider></el-divider>
      <el-main style="padding: 0"><router-view /></el-main>
    </el-container>
  </div>
</template>

<script>
import pubsub from "pubsub-js";
export default {
  name: "App",
  data() {
    return {
      activeIndex: "/index",
      initConnectedEventPID: null,
    };
  },
  methods: {
    handleSelect(index) {
      this.$router.push({
        path: index,
      });
    },
    notifyInitMessage() {
      this.$notify({
        title: "连接成功",
        message: `与服务器成功建立连接`,
        type: "success",
        duration: 2000,
      });
    },
  },
  created() {
    this.$store.dispatch("initSignalRConnection");
    this.initConnectedEventPID = pubsub.subscribe(
      "InitConnectedEvent",
      this.notifyInitMessage
    );
  },
  beforeDestroy() {
    if (this.initConnectedEventPID) {
      pubsub.unsubscribe(this.initConnectedEventPID);
    }
  },
};
</script>

<style>
html {
  height: 100%;
}
body {
  height: 100% !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
.el-container {
  height: 100%;
}
.el-header {
  background-color: #fff;
  color: #333;
  line-height: 60px;
  /* margin-bottom: 5px; */
}
.el-main {
  height: 100%;
}
.header-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
}
.header-container-left h1 {
  margin: 0;
  margin-left: 10px !important;
  display: inline-block;
  padding: 0;
}
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

* {
  scrollbar-width: 10px;
  scrollbar-base-color: green;
  scrollbar-track-color: red;
  scrollbar-arrow-color: blue;
}
</style>
