<template>
  <div style="height: 100%"><router-view /></div>
</template>

<script>
export default {
  name: "DocumentPage",
};
</script>

<style>
</style>