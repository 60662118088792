var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Index-Container"},[_c('div',{staticClass:"hero-bg"},[_c('header',{staticClass:"container-fluid py-3 px-4"},[_c('svg',{staticClass:"bottom-curve",attrs:{"viewBox":"0 0 834 99","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M242.5 2.48937C135.3 -9.90908 37.6667 26.7931 0 51.0969V99H834V14.4651C519.5 90.5465 449 26.3726 242.5 2.48937Z","fill":"white"}})]),_c('div',{staticClass:"row gy-5"},[_c('div',{staticClass:"col-lg-6 text-white",attrs:{"id":"Title"}},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"hero-cto mt-5"},[_c('button',{staticClass:"btn get-start btn-primary px-4 py-2 rounded-pill",staticStyle:{"background-color":"#8828ff","border-color":"#8828ff"},on:{"click":_vm.toUpload}},[_vm._v(" 即刻体验 ")])])]),_vm._m(2)])])]),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"hero-header mt-5"},[_vm._v(" 基于第三方大模型的,"),_c('br'),_vm._v("智能文档解析分析,"),_c('br'),_vm._v("针对文档内容提问系统 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"hero-subheader mt-3"},[_vm._v(" Based on a large model, "),_c('br'),_vm._v("Intelligently analyze and answer document content ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"hero-image"},[_c('img',{staticClass:"feature",attrs:{"src":require("../assets/img/右边图.png"),"alt":"hero feature image"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"task-section mt-0 mt-md-5 px-4",attrs:{"id":"About"}},[_c('div',{staticClass:"row align-items-lg-center task-content gx-2 gy-5 justify-content-between flex-column-reverse flex-lg-row"},[_c('div',{staticClass:"col col-lg-6"},[_c('img',{staticClass:"task-feature",attrs:{"src":require("../assets/img/中间左边图.png"),"alt":"task picture"}})]),_c('div',{staticClass:"col col-lg-6"},[_c('div',{staticClass:"taskSec-content mt-0 mt-md-5"},[_c('h1',{staticClass:"section-heading fs-1 mb-3"},[_vm._v("128K超大型国产大模型")]),_c('p',{staticClass:"section-subhead"},[_vm._v(" 理论最高支持128K字符数的文档解析与分析 "),_c('br'),_vm._v("12,800 - 满足解析99.99%文档的需求 "),_c('br'),_vm._v("百度国内自研ERNIE-Speed引擎解析速度极快 ")])])])]),_c('img',{staticClass:"round-shape",attrs:{"src":require("../assets/img/round.svg"),"alt":"round shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"chat-section mt-5 px-4"},[_c('div',{staticClass:"row chat-content align-items-lg-center gx-2 gy-5 justify-content-between flex-lg-row"},[_c('div',{staticClass:"col-lg-6 col-sm-12"},[_c('div',{staticClass:"chatSec-content mt-5",attrs:{"id":"Left-Content"}},[_c('h1',{staticClass:"section-heading fs-1 mb-3"},[_vm._v("文档信息,一览无余")]),_c('p',{staticClass:"section-subhead"},[_vm._v(" 智能分析文档的"),_c('span',[_vm._v("关键信息")]),_vm._v("、"),_c('span',[_vm._v("主体")]),_vm._v("、"),_c('span',[_vm._v("归纳总结")]),_vm._v("、"),_c('span',[_vm._v("重要段落")]),_vm._v("、"),_c('span',[_vm._v("重要句子")]),_vm._v("和"),_c('span',[_vm._v("术语")]),_vm._v(",以段落、列表和词云形式展现"),_c('br'),_vm._v("文档哪里重要，一看就知道 ")])])]),_c('div',{staticClass:"col-lg-6 col-sm-12"},[_c('img',{staticClass:"chat-feature",attrs:{"src":require("../assets/img/下面右边图.png"),"alt":"chat picture"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"position-relative",attrs:{"id":"testimonial"}},[_c('div',{staticClass:"testimonial-wrapper px-md-0 m-auto"},[_c('div',{staticClass:"row w-100 flex-wrap gx-0 gx-md-3"},[_c('div',{staticClass:"col col-md-5 ms-0 ms-lg-5 ps-4 ps-lg-5"},[_c('h1',{staticClass:"fs-1 section-heading testimonial-header text-white"},[_vm._v(" 简单好用,才是王道 ")]),_c('p',{staticClass:"section-subhead mt-3 testimonial-Subheader text-white"},[_vm._v(" 无需注册，开箱即用 "),_c('br'),_vm._v(" 便捷转换，一键Word ")])]),_c('div',{staticClass:"col col-md-6 testimonial-card-wrapper-col"},[_c('div',{staticClass:"d-flex px-3 testimonial-card-wrapper"},[_c('div',{staticClass:"testimonial-card p-4 py-3 py-md-5 me-0 me-md-3"},[_c('p',{staticClass:"desc"},[_vm._v(" 只需填写查询凭证和查询密码，无需注册，上传文件即刻进行分析 "),_c('br'),_vm._v(" 后台不存储任何文件，30天无访问，文件内容自动过期，保证隐私 ")]),_c('div',{staticClass:"author mt-3 d-flex align-items-center"},[_c('span',{staticClass:"ms-2"},[_vm._v("无需注册，开箱即用")])])]),_c('div',{staticClass:"testimonial-card p-4 py-3 py-md-5"},[_c('p',{staticClass:"desc"},[_vm._v(" 只支持解析Word格式文档，但提供PDF格式一键转换Word "),_c('br'),_vm._v(" 支持99.99% PDF格式，以及带密码、PDF/A读取转换 ")]),_c('div',{staticClass:"author mt-3 d-flex align-items-center"},[_c('span',{staticClass:"ms-2"},[_vm._v("便捷转换，一键Word")])])])]),_c('span',{staticClass:"testimonial-next rounded-circle d-flex justify-content-center align-items-center",attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa-solid fa-angle-right"})]),_c('span',{staticClass:"testimonial-prev bg-white rounded-circle d-flex justify-content-center align-items-center",attrs:{"aria-hidden":"true"}},[_c('i',{staticClass:"fa-solid fa-angle-left"})])])])])])
}]

export { render, staticRenderFns }