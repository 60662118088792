<template>
  <div class="QA-Item">
    <div class="Chat-FromUser-Container">
      <div class="Chat-Container-Header Chat-User-Header">
        <span class="Chat-Header-Span"> 用户 说:</span>
      </div>
      <div class="Content-RightFix">
        <div class="Chat-Answer-Content blueBGC">
          {{ QAData.question }}
        </div>
      </div>
    </div>
    <div class="Chat-FromAI-Container">
      <div class="Chat-Container-Header">
        <span class="Chat-Header-Span"> 智能文档分析助手 说:</span>
      </div>
      <div class="Chat-Answer-Content greenBGC">
        {{ QAData.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QAItem",
  props: ["QAData"],
};
</script>

<style scoped>
.QA-Item {
  padding-left: 10px;
}
.Chat-FromUser-Container {
  margin-bottom: 20px;
}
.Chat-Header-Span {
  font-size: 14px;
  color: #666;
}
.Chat-Container-Header {
  margin-bottom: 10px;
}
.Chat-Answer-Content {
  padding: 10px;
  border-radius: 10px;
  max-width: 75%;
  font-size: 18px;
}
.Chat-User-Header {
  display: flex;
  flex-direction: row-reverse;
}
.Content-RightFix {
  display: flex;
  flex-direction: row-reverse;
}
.greenBGC {
  background-color: #c2eeb8;
}
.blueBGC {
  background-color: #a9d5f2;
}
</style>