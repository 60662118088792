import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import * as signalR from '@microsoft/signalr';
import pubsub from 'pubsub-js';

const actions = {
  setRecordDeatilData(context, value) {
    context.commit('_setRecordDeatilData', value)
  },
  async initSignalRConnection({ commit }, value) {
    console.log("初始化SignalR连接");
    const transport = signalR.HttpTransportType.WebSockets;
    const options = { skipNegotiation: true, transport: transport };
    options.accessTokenFactory = () => value;

    // let connection = new signalR.HubConnectionBuilder()
    //   .withUrl("https://localhost:7049/Hubs/ReaderHub", options)
    //   .withAutomaticReconnect().build();
    let connection = new signalR.HubConnectionBuilder()
      .withUrl("https://auth.xn--e-5g8az75bbi3a.com/Hubs/ReaderHub", options)
      .withAutomaticReconnect().build();
    await connection.start();
    commit('keepConnectionData', connection);


    // 以下是收到新消息的回调
    connection.on("InitConnected", (msgRecordObj) => {
      pubsub.publish('InitConnectedEvent', msgRecordObj)
    })

    connection.on("ReceiveAskNewQuestionSuccess", (msgRecordObj) => {
      // 成功发送回答并获取到答案
      pubsub.publish('ReceiveAskNewQuestionSuccessEvent', msgRecordObj)
    })

    // 发送回答，但获取答案失败
    connection.on("ReceiveAskNewQuestionFailed", (failReason) => {
      pubsub.publish('ReceiveAskNewQuestionFailedEvent', failReason)
    })
  }
}
const mutations = {
  _setRecordDeatilData(state, value) {
    state.recordDetail = value;
  },
  keepConnectionData(state, value) {
    state.signalRConnection = value;
  },
  setRouterInfo(state, value) {
    console.log("收到路由信息", state, value);
    state.routerName = value;

    if (value === "Index") {
      state.routerNavigatorName = "/index";
    } else if (value === "Upload") {
      state.routerNavigatorName = "/upload";
    } else if (value.includes("Document")) {
      state.routerNavigatorName = "/document/query"
    } else {
      state.routerNavigatorName = "/convert"
    }
  }
}
const state = {
  recordDetail: null,
  signalRConnection: null,
  routerName: null,
  routerNavigatorName: null
}

export default new Vuex.Store({
  actions,
  mutations,
  state
})