<template>
  <div
    class="ConvertPage-Container"
    v-loading="isLoading"
    element-loading-text="正在转换中，请不要刷新页面"
  >
    <el-alert
      title="温馨提示"
      type="info"
      description="目前仅支持PDF文件转换为Word(.Docx)格式文件，请上传大小合适不要过大的PDF文件，并耐心等待转换"
      show-icon
      style="margin-bottom: 20px"
    >
    </el-alert>
    <el-form label-position="top" label-width="80px">
      <el-form-item label="转换格式选择">
        <el-select v-model="selectedFormat" placeholder="请选择">
          <el-option-group
            v-for="group in formatsOptions"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="待转换文件">
        <el-upload
          class="upload"
          drag
          accept=".pdf"
          ref="upload"
          action="https://auth.xn--e-5g8az75bbi3a.com/api/v1/Reader/Documents/Convert/pdf2word"
          :http-request="uploadToConvert"
          :auto-upload="true"
          :limit="1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖拽到此处，或<em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            只允许上传.pdf文件，尽量不要过大
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ConvertPage",
  data() {
    return {
      formatsOptions: [
        {
          label: "PDF格式相关",
          options: [
            {
              value: "pdf2word",
              label: "PDF ➡️ Word",
            },
          ],
        },
      ],
      selectedFormat: "pdf2word",
      isLoading: false,
    };
  },
  methods: {
    uploadToConvert(data) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("pdfFile", data.file);

      // 获取原始文件名并创建新的下载文件名
      const originalFileName = data.file.name;
      const baseFileName = originalFileName.replace(/\.[^/.]+$/, ""); // 移除扩展名
      const newFileName = `${baseFileName} - converted.docx`;

      axios({
        url: "https://auth.xn--e-5g8az75bbi3a.com/api/v1/Reader/Documents/Convert/pdf2word",
        method: "POST",
        data: formData,
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", newFileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          this.$refs.upload.clearFiles();
          this.isLoading = false;
          this.$notify({
            title: "转换成功",
            message: `您的文件 ${newFileName} 已转换成功，请下载`,
            type: "success",
          });
        })
        .catch((error) => {
          console.error("转换失败:", error);
          this.isLoading = false;
          this.$notify.error({
            title: "转换失败",
            message: error,
          });
        });
    },
  },
};
</script>

<style>
.ConvertPage-Container {
  padding-left: 25%;
  padding-right: 25%;
}
</style>